import React from "react"
import { Link } from "gatsby"
import { PhotoPlaceholder } from "react-placeholder-image"

import { AiOutlineBuild } from "react-icons/ai"
import {
  GiTechnoHeart,
  GiChameleonGlyph,
  GiRetroController,
  GiStoneThrone,
} from "react-icons/gi"
import { FaFacebookSquare, FaInstagram, FaWhatsapp } from "react-icons/fa"

import LazyLoad from "react-lazyload"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div className="grid-1-2 has-mb-5 height-fix">
      <div
        className="has-padding-1 has-bg-blue has-txt-white align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon">
          <AiOutlineBuild />
        </span>
        <h1 className="has-txt-bold has-mb-5">
          We build ecosystems that nurture ideas to fruition.
        </h1>{" "}
        <p className="has-mb-5">
          We are all about sustainable design driven solutions that deliver
          great experiences. Keeping visual storytelling at the core, we build
          brand ecosystems and let them grow organically.
          <br /> <br />
          <span className="social">
            <a
              target="_blank"
              href="https://www.facebook.com/digitalllyinklined/"
            >
              <FaFacebookSquare />
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/digitallyinklined/"
            >
              <FaInstagram />
            </a>
          </span>
        </p>
        <h2 className="has-txt-bold"></h2>
      </div>
      <div className="grid-2-2">
        <Link to="/work/gym">
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/gym/1.jpg"} alt="" />
          </div>
        </Link>
        <Link to="/work/fruitsmith">
          <div
            data-sal="slide-up"
            data-sal-delay="400"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/fsmith/1.jpg"} alt="" />
          </div>
        </Link>
        <div
          data-sal="slide-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          data-sal-duration="900"
          className="has-padding-4  has-bg-light align-content-vcenter"
          style={{ textAlign: "center" }}
        >
          <Link
            to="/work"
            style={{ display: "block", width: "100%", color: "black" }}
          >
            <h2
              className="has-txt-bold"
              style={{ display: "block", width: "100%" }}
            >
              <span className="icon">
                <GiRetroController />
              </span>{" "}
              <br />
              Our work. <br />
            </h2>
          </Link>
        </div>
        <Link to="/work/stimulate">
          <div
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/stimulate/1.jpg"} alt="" />
          </div>
        </Link>
      </div>
    </div>

    <div className="grid-1-2 has-mb-5  height-fix">
      <div className="grid-2-2">
        <Link to="/work/karuneshwari">
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/karuneshwari/1.jpg"} alt="" />
          </div>
        </Link>
        <Link to="/work/agave">
          <div
            data-sal="slide-up"
            data-sal-delay="300"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/agave/1.jpg"} alt="" />
          </div>
        </Link>
        <div
          data-sal="slide-up"
          data-sal-delay="500"
          data-sal-easing="ease"
          data-sal-duration="900"
          className="has-padding-4  has-bg-light align-content-vcenter"
          style={{ textAlign: "center" }}
        >
          <Link
            to="/services"
            style={{ display: "block", width: "100%", color: "black" }}
          >
            <h2
              className="has-txt-bold"
              style={{ display: "block", width: "100%" }}
            >
              <span className="icon">
                <GiChameleonGlyph />
              </span>{" "}
              <br />
              Our services. <br />
            </h2>
          </Link>
        </div>
        <Link to="/work/yogsattva">
          <div
            data-sal="slide-up"
            data-sal-delay="600"
            data-sal-easing="ease"
            data-sal-duration="900"
          >
            <img className="is-fit" src={"/images/yogsattva/2.jpg"} alt="" />
          </div>
        </Link>
      </div>
      <div
        className="has-padding-1 has-bg-light align-content-vcenter"
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="900"
      >
        <span className="icon" style={{ color: "rebeccapurple" }}>
          <GiTechnoHeart />
        </span>
        <h1 className="has-txt-bold has-mb-5">
          We are all about solutions, sustainability and great experiences.{" "}
        </h1>{" "}
        <p>
          We are a team brought together by the idea of disrupting the way a
          digital agency is perceived. <br />
          <br /> Driven by ideas and belief in design driven solutions, we
          develop brand ecosystems making them intuitive and interactive.
        </p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
